import Button from "@/components/Button";
import Form from "@/components/Form";
import TextField from "@/components/TextField";
import { useAuthContext } from "@/contexts/AuthContext";
import { SessionContext } from "@/contexts/SessionContext";
import { useStores } from "@/hooks/useStores";
import { pushEvent } from "@/lib/gtm";
import clsx from "clsx";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { passwordHint, passwordRegex } from "lib/constants";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import useGTM from "@/hooks/useGTM";

const SignUpForm = observer(({ onStatusChange = (value) => undefined }) => {
  const gtm = useGTM();
  const { authStatus, setAuthStatus, currentEmail, setCurrentEmail } =
    useAuthContext();

  const { setUserId } = useContext(SessionContext);

  const router = useRouter();

  // Reset auth status on mount
  useEffect(() => {
    setAuthStatus("idle");
  }, []);

  // set couponCode to use in stripe at checkout
  useEffect(() => {
    if (router.isReady) {
      if (router.query["coupon_code"]) {
        sessionStorage.setItem(
          "couponCode",
          router.query["coupon_code"] as string
        );
      } else if (process.env.NEXT_PUBLIC_AUTO_APPLIED_COUPON) {
        sessionStorage.setItem(
          "couponCode",
          process.env.NEXT_PUBLIC_AUTO_APPLIED_COUPON
        );
      } else if (
        !process.env.NEXT_PUBLIC_AUTO_APPLIED_COUPON &&
        sessionStorage.getItem("couponCode")
      ) {
        sessionStorage.removeItem("couponCode");
      }
    }
  }, [router.isReady]);

  const { profileStore, authStore } = useStores();
  const { setIsProfileWizardActive } = profileStore;

  const [emailCheckCount, setEmailCheckCount] = useState(0); // let's only allow this a few times

  const handleNextClick = async (email, setFieldError) => {
    // First make sure the email is valid before we do anything else
    try {
      await validationSchema.validateAt("email", { email });
    } catch (error) {
      setFieldError("email", error.message);
      setAuthStatus("invalid");
      return;
    }

    // Don't do anything if we're already checking
    if (authStatus === "checking") return;

    // If we've checked the email too many times, just bail
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV === "production" &&
      emailCheckCount >= 3
    ) {
      setAuthStatus("error");
      return;
    }

    // Increment the count and set the status to checking
    setEmailCheckCount(emailCheckCount + 1);

    // Set the status to checking
    setAuthStatus("checking");

    // Call the API to check if the email is already in use, using POST. It returns a boolean
    try {
      const response = await fetch("/api/auth/check-email", {
        method: "POST",
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      console.log("data", data);

      // If the email is already in use, set the status to exists
      setAuthStatus(data ? "exists" : "available");
    } catch (error) {
      // If the api route is not available, let's just assume the email is available
      setAuthStatus("available");
    }
  };

  const handleSubmit = (values, actions) => {
    const { setSubmitting, setErrors, setFieldError } = actions;

    const submitAction = async () => {
      // Sign the user up
      const signUpResponse = await authStore.signUp(values);

      // If there are errors, set them on the form
      if (!signUpResponse.success) {
        setFieldError("email", signUpResponse.error);
        setSubmitting(false);
        setAuthStatus("error");
        return false;
      }

      const loginResponse = await authStore.login({
        loginName: values.email,
        password: values.password,
      });

      if (!loginResponse.success) {
        setErrors(loginResponse.errors);
        setSubmitting(false);
        setAuthStatus("error");
        return;
      }

      // If we're here, the user has signed up and logged in successfully
      if (loginResponse.success) {
        setAuthStatus("success");

        authStore.setIsAuthActive(false);
        setIsProfileWizardActive(true);
        setSubmitting(false);
        setUserId(authStore.currentUser.id);

        gtm.pushEvent("free_account_created");

        pushEvent("account_signup", {
          user_id: authStore.currentUser.id,
        });
      }
    };

    submitAction();
  };

  useEffect(() => {
    if (authStatus) onStatusChange(authStatus);
  }, [setAuthStatus, onStatusChange, authStatus]);

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: currentEmail || "",
          password: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
      >
        {({
          isSubmitting,
          handleReset,
          handleSubmit,
          values,
          setFieldError,
          handleChange,
          setErrors,
        }) => {
          const handleEmailChange = (ev) => {
            handleChange(ev);
            setCurrentEmail(ev.target.value);
            setErrors({});
            if (authStatus !== "idle") setAuthStatus("idle");
          };

          const showEmailExistsMessage = authStatus === "exists";
          const showEmailCheckButton =
            authStatus !== "available" && authStatus !== "success";
          const isChecking = authStatus === "checking";
          const isError = authStatus === "error";
          const showFieldsGroup =
            authStatus === "available" || authStatus === "success";

          return (
            <Form handleReset={handleReset} handleSubmit={handleSubmit}>
              <div className="flex min-h-full flex-col justify-between">
                {/* Scrollable & Centered content */}
                <div className="flex flex-1 items-center justify-center px-4">
                  <div className="w-full max-w-md space-y-6">
                    <h1 className="font-spatial text-4xl font-bold">
                      Create your account
                    </h1>

                    <TextField
                      name="email"
                      label="Email"
                      placeholder="Enter your email"
                      type="email"
                      theme="light"
                      value={values.email}
                      onChange={handleEmailChange}
                    />

                    <AnimatePresence>
                      {showEmailExistsMessage && (
                        <motion.div className="font-body text-sm">
                          It looks like you might already have an account.{" "}
                          <Link
                            href="/signin"
                            className="underline underline-offset-2 transition hover:opacity-75"
                          >
                            Log In instead.
                          </Link>
                        </motion.div>
                      )}
                    </AnimatePresence>

                    {showEmailCheckButton && (
                      <Button
                        key="next"
                        label={
                          isChecking ? "Please wait..." : "Try Mob for free"
                        }
                        type="submit"
                        isDisabled={isChecking || isError}
                        isFull
                        theme="light"
                        onClick={() => {
                          posthog.capture("onboarding_signup_button_click");
                          handleNextClick(values.email, setFieldError);
                        }}
                      />
                    )}

                    {showFieldsGroup && (
                      <_FieldsGroup
                        authStatus={authStatus}
                        isSubmitting={isSubmitting}
                        setErrors={setErrors}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
});

const _FieldsGroup = ({ authStatus, isSubmitting, setErrors }) => {
  // Reset errors on mount
  useEffect(() => {
    setErrors({});
  }, []);

  return (
    <div className={clsx("space-y-4", {})}>
      <div className={clsx("flex gap-4")}>
        <div className={clsx("w-1/2")}>
          <TextField
            name="firstName"
            label="First name"
            placeholder="Enter your first name"
            type="text"
            theme="light"
            //  focusOnMount
          />
        </div>
        <div className={clsx("w-1/2")}>
          <TextField
            name="lastName"
            label="Last name"
            placeholder="Enter your last name"
            type="text"
            theme="light"
          />
        </div>
      </div>

      <TextField
        name="password"
        label="Password"
        placeholder="Enter a password"
        type="password"
        theme="light"
      />

      <p className="text-grey-900 py-3 text-center font-body text-sm">
        By signing up you agree to the Mob{" "}
        <a
          href="/mob-plus-terms-and-conditions"
          className="underline underline-offset-2 hover:opacity-75"
          target="_blank"
        >
          Terms and Conditions
        </a>
      </p>

      <div>
        <Button
          key="submit"
          label={
            isSubmitting || authStatus === "success"
              ? "Please wait..."
              : "Join the Mob"
          }
          type="submit"
          isDisabled={isSubmitting || authStatus === "success"}
          isFull
          theme="light"
        />
      </div>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Your first name is required"),
  lastName: Yup.string().required("Your last name is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Your email address is required"),
  password: Yup.string()
    .required("Please enter a password")
    .matches(passwordRegex, passwordHint),
});

export default SignUpForm;
